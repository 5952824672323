import React from 'react';

import Layout from "../../components/layout";
import { Link } from 'gatsby';
import { Title,Testonimal } from "../../components/Globals";
import {Helmet} from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import  "./awesome-slider-override.css";
// import 'react-awesome-slider/src/styles.js';

const SalesforceConsultingPage = () => {

  const [ExploreJobs] = React.useState([

    {
      id: 1,
      title: '70%',
      desc: 'The online shopping cart abandonment rate is 70%',
    },
    {
      id: 2,
      title: '47%',
      desc: 'Consumers are likely to spend 47% more when they have a personalized experience'
    },
    {
      id: 3,
      title: '73%',
      desc: 'Said the return experience affected whether they would continue shopping with a retailer'
    },
  ]);

  const [services] = React.useState([
    {
      id: 1,
      title: 'Marketing Automation Experience',
      desc: '10+ years of experience with successful installation across multiple segments.',
      image: 'marketing-automation-services-india-usa.png',
      alt:'Salesforce marketing automation services USA India',
      imagetitle:'Experience ',
      url: {
       
      }
    },
    {
      id: 2,
      title: 'Certifications',
      desc: 'Team with 115+ Certified Salesforce professionals. ',
      image: 'salesforce-marketing-automation-certified-consultants.png',
      alt:'marketing automation Salesforce certified consultants India USA',
      imagetitle:'Salesforce Certifications',
      url: {
      }
    },
    {
      id: 3,
      title: 'End-to-End Support',
      desc: 'One stop shop for the entire spectrum of marketing automation, including implementation, strategy, content, campaigns and analytics. ',
      image: 'marketing-automation-agencies-india-usa.png',
      alt:'marketing automation agencies India',
      imagetitle:'Salesforce Support',
      url: {
      }
    },
    {
      id: 4,
      title: 'Industry Expertise',
      desc: 'We understand industry specific challenges and infuse strategic guidance & best practices in our solutions. ',
      image: 'marketing-automation-companies.png',
      alt:'marketing-automation-companies',
      imagetitle:'Industry Experience',
      url: {
      }
    },
 
  ]);

  const testimonials = [
    {
      customerName: "David Hanson",
      customerProfile: "CEO, Koalify",
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
      quote:
        "We have been using servana for about 2 years. And in that time we have had no problem at all. The user interface is really simple to use. Our services scale automatically and we never have to worry about downtimes. is as described."
    },
    {
      customerName: "Serena Davis",
      customerProfile: "Founder, Travana",
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=256&h=256&q=80",
      quote:
        "We are delighted with the quality and performance of the servers that servana provides. The uptime is amazing and the internet connection is great for the price we are paying."
    },
    {
      customerName: "Timothy Burr",
      customerProfile: "CTO, Coronax",
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=256&h=256&q=80",
      quote:
        "It has been 8 months since we have switched to servana and it has nothing but an amazing experience. The cost is affordable, support is great, uptime is as described."
    }
  ];

  return (
    <Layout>
       
        <div className="salesforce-consulting h-screen">
        <Helmet>
        <title>Marketing Automation For E-Commerce | India, USA, UK, Poland</title>
        <meta name="description" content="Cymetrix is one of the top marketing automation companies, providing end-to-end support of automation platform implementation services, creative, campaign management, etc."/>
        <meta name="keywords" content="cymetrix,marketing automation services, marketing automation, marketing automation agencies, marketing automation companies, marketing automation service providers, marketing automation india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/services/marketing-automation-for-ecommerce/" />
      </Helmet>
            <h1 className="text-base xl:text-4xl xl:m-64 md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
            text-white text-uppercase text-center">
            From Clicks to Cart: Drive Ecommerce growth with Marketing Automation            </h1>
        </div>
        
        <div className="max-w-6xl mx-auto justify-center my-3 md:my-12 ">
          <h1 className="text-3xl my-3 sma:mx-3">Salesforce Marketing Automation For E-Commerce</h1>
          <p className="text-lg sma:mx-3">Are you looking to turn window shoppers into loyal customers? Cymetrix can help you leverage the power of Salesforce marketing automation to maximize conversions for your Ecommerce store. Our Salesforce Consulting partner will implement custom solutions so that your visitors have a seamless and personalized experience through every step of the buying journey, from browsing to checkout.  </p>
        </div>

        <div className="job-background w-full flex flex-col items-center lg:mb-8">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="  " classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-4 md:py-14">
                {
                  ExploreJobs.map(job => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/3 sma:w-full" key={job.id}>
                          <div className="bg-white p-4 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <h1 className="text-2xl mt-5 text-center">{job.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{job.desc}</p>
                          </div>
                      </div>
                  })
                }
              </div>

          </div>
        </div>

        <div className="section-title text-center m-8">
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>E-Commerce Marketing Pain Points
          </span>
        </div>
        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Deliver personalized experience </div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">As the Ecommerce industry continues to become more crowded, generic marketing tactics simply don't cut it anymore. Customers crave experiences that feel personal and relevant. They want to be seen, not just sold to. At Cymetrix, we understand this and have helped several Ecommerce brands embrace this approach with our expertise in marketing automation. 
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Offering multi-channel experience </div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Nowadays customers prefer using all sorts of channels to shop or browse products online. They could be comparing your product on social media, researching on the web, or visiting your store. But are you reaching them at each touchpoint? With Cymetrix’s marketing automation services, powered by the Salesforce marketing cloud, you can interact with customers through every channel and make every marketing moment count.  
                  
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Abandoned Cart Recovery </div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">Shoppers abandon carts for all sorts of reasons. Maybe they got distracted, comparison shopping, or simply forgot. But it can be quite tricky for an Ecommerce brand to recover these abandoned shoppers. However, our experts at Cymetrix can help you design and implement marketing automation solutions targeted toward recovering these abandoned carts. So, you can convert those abandoned carts into sales, not lost opportunities.
                 
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Drive repeated sales and retain customers</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Recovering abandoned carts is just the first step, in building a loyal customer base, and retaining existing customers to drive repeated sales is crucial for an Ecommerce business to survive in a competitive market. Our team of Marketing Cloud Consultants can help you customize and set up Marketing Cloud, so you can trigger automated campaigns that re-engage customers with relevant offers, or, programs that incentivize repeat sales and build a community of brand advocates.
                  
                  </div>
                </div>
              </div>
              
            </div>

          </div>
        </div>

        <div className="product-background w-full flex flex-col items-center" title="">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">E-Commerce Automation Solutions By Cymetrix</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                    <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Automate the buying journey at every touchpoint</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">At Cymetrix, we understand the struggle of keeping up with customers at every stage of the buying journey at every touchpoint. So, we can help you unlock the power of Salesforce Marketing Cloud and automate your shopping journey at every possible touchpoint. Say goodbye to the clunky process that leads to abandoned carts, and embrace a seamless experience that nurtures leads, and converts browsers into buyers.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Craft Personalize Customer Journeys, Not Just Campaigns</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Are you tired of creating campaigns that don’t convert? Our team of certified professionals will work closely with you to unleash the full potential of the industry-leading platform, crafting tailored customer journeys that captivate and convert. From seamless data integration to intuitive campaign management, we'll help you create a personalized experience that strengthens brand loyalty and drives sustainable growth.  </div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">In-store-like experience on your digital storefront</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Our team at Cymetrix understands very well the significance of delivering a shopping experience like that of an offline store. We can help you design and automate your digital storefront in a way that your shoppers feel like they are literally in a store. From real-time chatbots and customer support for assistance to automated product recommendations, we can help you customize and integrate everything you need to transform your digital storefront.  </div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Integrate and Create a unified view across all clouds</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Say goodbye to data silos and embrace a unified and 360-degree view of your Ecommerce customers across all Clouds. With our expertise in Salesforce Integration, we can help you integrate Marketing Cloud with your existing Commerce platform or CRM to integrate data on all platforms. As a result, not only do you understand your customers better, but also easily identify KPIs and potential opportunities.   </div>
                            </div>
                        </div>




                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>


            <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Drive connected experiences with Salesforce and Mulesoft  														
              </h1><br />
              <p className="font-hairline text-justify">Leverage the seamless synergy of Salesforce and MuleSoft with Cymetrix—your trusted partner in crafting customized Salesforce solutions. As  <Link to="/services/salesforce-consulting/" class="underline text-blue-700"> Salesforce Partners</Link> and experts, we bring a unique blend of experience to drive connected experiences, utilizing the power of MuleSoft to build flexible architectures, unlock cross-system data, and create differentiated, connected solutions at an accelerated pace.				
              </p><br/>
              </div>

              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-mulesoft-integration-services.png')}
                      alt="Salesforce Mulesoft Integration Services"
                      title="Accelerate digital transformation with Salesforce and Mulesoft"
                      />
                </div>
              </div>
          </div>
        </div>


        <div className="product-background w-full flex flex-col items-center" title="">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Our Expertise Across Marketing Cloud Suite</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                    <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Marketing Cloud Engagement</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Drive meaningful engagement with your audience through an integrated approach spanning email, mobile, web, and advertising channels. Craft seamless journeys across the entire customer lifecycle, empowered by robust analytics to continually refine and enhance your campaigns.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Marketing Cloud Personalization</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Get a comprehensive view of customers across multiple touchpoints. Then leverage customer data to automate and personalize customer journeys across multiple channels in real-time. </div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Marketing Cloud Intelligence</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Revolutionize your marketing efforts with our Marketing Intelligence solutions powered by Datorama. Drive customer engagement, boost conversions, and execute targeted marketing campaigns with precision and efficiency.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Data Cloud for Marketing</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Unify your customer Data with Data Cloud for Marketing. Transform your business with real-time insights, 360° view of customer data and deliver hyper-personalized experience at scale.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Marketing Cloud Account Engagement (Pardot)</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Experience a comprehensive solution for all your marketing automation needs. Reimagine your business by streamlining your sales pipeline and driving overall sales growth through our innovative solutions powered by Pardot. </div>
                            </div>
                        </div>


                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>

          <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              CYMETRIX SALESFORCE EXPERTISE 
 														
              </h1><br />
              <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/top-salesforce-implementation-partners-usa-india-uk.png')}
                      alt="Salesforce Partners Marketing Automation"
                      title="Cymterix Salesforce Expertise "
                      />
              </div>
              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">               
             
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
             SALESFORCE CERTIFIED TEAM 
 														
              </h1><br /> 
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-certified-consultants-usa-india.png')}
                      alt="mumbai marketing automation usa"
                      title="Skilled team of Salesforce certified consultants"
                      />
                
              </div>
          </div>
        </div>

        <div className="service-background w-full flex flex-col items-center ">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="Why Choose Cymetrix for E-Commerce Marketing Automation Services" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                {
                  services.map(service => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/4" key={service.id}>
                          <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">
                              <img src={require(`../../images/services/${service.image}`)} title={service.imagetitle} alt={service.alt} className="w-24 h-24"/>
                              </div>
                              <h1 className="mt-5 text-center">{service.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{service.desc}</p>
                              <Link to={service.url.url} className="mt-5 text-hover text-lg text-center">{service.url.title}</Link>
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
        </div>


        <div className="bg-gray-300 w-full flex flex-col items-center ">
            <div className="max-w-5xl mx-4 sm:mx-2 sma:mx-2">
                <h1 className="text-3xl w-full text-gray-800 text-uppercase text-center mt-20 mb-5 md:text-3xl sma:text-xl">
                A TRUSTED SALESFORCE PARTNER IN USA & INDIA
                </h1>
                <div className="flex flex-col md:flex-row items-center content-between my-10">
                  <img src={require('../../images/home/salesforce-consulting-partner.png')} className="w-48" alt="top Salesforce consulting partner company"/>
                  <p className="mx-5 sma:my-10 text-gray-700 text-justify">Cymetrix is one of the leading Salesforce implementation partners in USA with experience and expertise to drive Salesforce-powered digital transformation for your enterprise. Our offshore Salesforce development team can help clients leverage the Salesforce platform effectively to innovate and create value from customer operations.</p>
                </div>
            </div>
        </div>


        <div className="section-title text-center m-8">
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>E-Commerce Models We Serve
          </span>
        </div>
        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Business to Business (B2B) </div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Redefine commerce and give B2C-like shopping experiences to your B2B customers with complete transparency around product prices, catalogs, quotes, and seamless visibility to sales & service reps. 
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Business to Consumer (B2C) </div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Maximize conversions and sales with our expertise in Marketing automation. Our team of certified platform developers leverages cutting-edge technology to create custom solutions and help you deliver exceptional customer experiences (CX). 
                  
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Business to Business to Consumer (B2B2C) </div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">Our innovative B2B2C eCommerce approach empowers Manufacturers and distributors by building new revenue streams. We'll help you build a seamless online experience for your B2B partners, allowing them to connect directly with end consumers. This B2B2C approach opens doors to a wider customer base and unlocks exciting growth opportunities for your business. 
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Digital Marketplaces </div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">We're your one-stop shop for dominating the digital marketplace.  Our comprehensive services encompass platform development and implementation, marketing automation, multi-platform integration, catalog and user management, robust analytics, and reporting. With Cymetrix, you’ll have everything you need to thrive online.
                  
                  </div>
                </div>
              </div>
              
            </div>

          </div>
        </div>

        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">Case Studies</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-consulting-services-usa-pharma.png')} alt="Salesforce consulting partners pharma case study | Cymterix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/b2b-commerce-cloud-pharma/" >Streamlining global wholesale operations with Salesforce & SAP for a pharmaceutical company</Link></h1>
                          
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partner-india-fintech.png')} alt="Salesforce consulting fintech company | Cymetrix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/fintech-community-cloud/">Revolutionizing bill discounting with a self-serving co-lending fintech platform</Link></h1>
                            
                        </div>
                    </div>
                </div>
            </div>




        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partners-license-types.png')} alt="Salesforce partners for licencing | Cymterix Software" title="Salesforce license types" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/02/understanding-salesforce-license-types-for-optimization-and-cost-reduction/" >Understanding Salesforce License Types For Optimization and Cost Reduction</Link></h1>
                            <p className="text-lg">Read about the core attributes of Salesforce.com licensing agreements.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-einstien-gpt-ai.png')} alt="Salesforce Einstien GPT | Cymetrix Software" title="Why Salesforce generative AI is the next big thing?" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/07/salesforce-einstein-gpt-why-generative-ai-is-the-next-big-thing/">Salesforce Einstein GPT: Why Generative AI is the next big thing?</Link></h1>
                            <p className="text-lg">Read about how Salesforce Einstien is revolutionizing the way CRM works.</p>
                        </div>
                    </div>
                </div>
            </div>

        <Testonimal />
        


        <div className="flex flex-col mx-auto items-center rounded-lg pb-10">
                    <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1"/>
                    <div className="px-6 py-4 bg-white  text-gray-700">
                        <h4 class="text-lg" style={{color: '#1e407b'}}><b>FAQs:</b></h4>
                        <br/>
                        <h4 class="text-lg"><b>Why choose Cymetrix as your partner for Salesforce Marketing Automation Services? </b></h4>
                        <p> Cymetrix brings unparalleled expertise in Salesforce Marketing Automation, offering tailored solutions to amplify your marketing efforts. Our team is dedicated to maximizing your marketing ROI through personalized and custom marketing solutions.</p>
                        <br/>
                        <h4 class="text-lg"><b>Which businesses can benefit from Cymetrix’s Marketing Automation services? </b></h4>
                        <p> Cymetrix's Marketing Automation services cater to a diverse range of businesses across industries, including e-commerce, healthcare, finance, manufacturing, and more. Whether you're a startup or an enterprise, our solutions are designed to streamline your marketing processes and drive tangible results.</p>
                        <br/>
                        <h4 class="text-lg"><b>In which location does Cymetrix offer Salesforce Marketing Automation Services?</b></h4>
                        <p>We provide near-shore consultation services and offshore delivery powered by our network of offices in USA, UK, Europe, Japan and India.</p>

                    </div>
                </div>


      </Layout>
    );
}

export default SalesforceConsultingPage;
